

















import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api';
import HeaderTopGA4 from "~/components/FortyTwo/header/HeaderTopGA4.vue";
import CheckoutSteps from "~/components/FortyTwo/checkout/V1/CheckoutSteps.vue";

export default defineComponent({
    name: 'FortytwoAppCheckoutHeaderV1',
    components: {
        HeaderTopGA4,
        CheckoutSteps
    },
    setup() {
        const route = useRoute();
        const currentStep = computed(() => route.value.path.split('/').pop());
        const isThankYou = computed(() => currentStep.value === 'thank-you');

        return {
            isThankYou,
        }
    }
})
