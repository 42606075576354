



























import {
  defineComponent, ref,
} from '@nuxtjs/composition-api';
import { debounce } from "lodash-es";
import HeaderNavigationItemCustom from '~/components/FortyTwo/header/NavigationCustom/HeaderNavigationItemCustom.vue';

export default defineComponent({
  name: 'HeaderNavigation',
  components: {
    HeaderNavigationSubcategoriesCustom: () => import('~/components/FortyTwo/header/NavigationCustom/HeaderNavigationSubcategoriesCustom.vue'),
    HeaderNavigationItemCustom,
  },
  props: {
    megaMenu: {
      type: Object,
      default: () => [],
    },
  },
  setup() {
    const currentCategoryId = ref(false);
    const setCurrentCategoryId = (category_id) => {
      currentCategoryId.value = category_id;
    };

    const hasChildren = (category) => Boolean(category?.content);

    const onMouseEnter = (category_id) => {
      currentCategoryId.value = category_id;
    };

    const delayedOnMouseEnter = debounce(
      (params) => onMouseEnter(params),
      250,
    );

    const delayedOnMouseLeave = debounce(
      (params) => setCurrentCategoryId(params),
      250,
    );

    return {
      setCurrentCategoryId,
      currentCategoryId,
      hasChildren,
      onMouseEnter,
      delayedOnMouseEnter,
      delayedOnMouseLeave
    };
  },
});
