




























import { defineComponent, ref, computed, useRoute, useRouter, useContext } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';

export default defineComponent({
    name: 'FortytwoCheckoutStepsV1',
    components: {
        SfIcon,
    },
    setup() {
        const STEPS = ref([
        {
            title: 'Delivery Address',
            url: 'shipping',
            imgPath: '/custom/checkout/your_details.svg',
            imgPathActive: '/custom/checkout/your_details_active.svg',
        },
        {
            title: 'Billing Address',
            url: 'billing',
            imgPath: '/custom/checkout/your_details.svg',
            imgPathActive: '/custom/checkout/your_details_active.svg',
        },
        {
            title: 'Delivery Date/Time',
            url: 'delivery-date',
            imgPath: '/custom/checkout/delivery.svg',
            imgPathActive: '/custom/checkout/delivery_active.svg',
        },
        {
            title: 'Payment Method',
            url: 'payment',
            imgPath: '/custom/checkout/payment.svg',
            imgPathActive: '/custom/checkout/payment_active.svg',
        },
        {
            title: 'Order Review',
            url: 'order-review',
            imgPath: '/custom/checkout/payment.svg',
            imgPathActive: '/custom/checkout/payment_active.svg',
        },
        ]);
        const route = useRoute();
        const router = useRouter();
        const { app } = useContext();
        const currentStep = computed(() => route.value.path.split('/').pop());
        const currentStepIndex = computed(() => STEPS.value.findIndex((step) => step.url === currentStep.value));
        
        const handleStepClick = async (stepIndex: number) => {
            if (stepIndex <= currentStepIndex.value) {
                const { url } = STEPS.value[stepIndex];
                await router.push(`${app.localePath(`/checkout/${url}`)}`);
            }
        };

        const isClickable = (key) => key < currentStepIndex.value;

        return {
            STEPS,
            currentStep,
            currentStepIndex,
            handleStepClick,
            isClickable,
        };

    }
});
