import { defineStore } from 'pinia';
import type { Customer } from '~/modules/GraphQL/types';

interface CustomerState {
  user: Customer | null,
  isLoggedIn: boolean,
  triggerLoginModal: boolean,
  triggerResetPasswordModal: boolean,
  triggerRefreshPage: boolean,
  group_id: number,
}

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    user: null,
    isLoggedIn: false,
    triggerLoginModal: false,
    triggerResetPasswordModal: false,
    triggerRefreshPage: false,
    group_id: 0,
  }),
  actions: {
    setIsLoggedIn(isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn;
    },
    setTriggerLoginModal(triggerLoginModal: boolean) {
      this.triggerLoginModal = triggerLoginModal;
    },
    setTriggerResetPasswordModal(triggerResetPasswordModal: boolean) {
      this.triggerResetPasswordModal = triggerResetPasswordModal;
    },
    setTriggerRefreshPage(triggerRefreshPage: boolean) {
      this.triggerRefreshPage = triggerRefreshPage;
    },
    setCustomerGroupId(group_id: number) {
      this.group_id = group_id;
    },
  },
});
