export interface SortingModel {
  selected: string,
  options: SortingOption[]
}

export interface SortingOption {
  label: string,
  value: SortingOptionsValuesEnum
}

export enum SortingOptionsValuesEnum {
  DEFAULT = '',
  NAME_ASC = 'name_ASC',
  NAME_DESC = 'name_DESC',
  PRICE_ASC = 'price_ASC',
  PRICE_DESC = 'price_DESC',
  POSITION_ASC = 'position_ASC',
  POSITION_DESC = 'position_DESC',
  BESESELLERS_ASC = 'bestsellers_ASC',
  BESESELLERS_DESC = 'bestsellers_DESC',
  CALCULATED_LEAD_TIME_ASC = 'calculated_lead_time_ASC',
  NEW_DESC = 'new_DESC',
  RATING_SUMMARY_DESC = 'rating_summary_DESC',
  REVIEWS_COUNT_DESC = 'reviews_count_DESC',
}

export const sortingOptions: SortingOption[] = [
  {
    label: 'Best Value: Best First',
    value: SortingOptionsValuesEnum.POSITION_DESC,
  },
  {
    label: 'Price: Low to High',
    value: SortingOptionsValuesEnum.PRICE_ASC,
  },
  {
    label: 'Price: High to Low',
    value: SortingOptionsValuesEnum.PRICE_DESC,
  },
  {
    label: 'Delivery: Fastest first',
    value: SortingOptionsValuesEnum.CALCULATED_LEAD_TIME_ASC,
  },
  {
    label: 'New: Newest first',
    value: SortingOptionsValuesEnum.NEW_DESC,
  },
  {
    label: 'Best Seller: Best first',
    value: SortingOptionsValuesEnum.BESESELLERS_DESC,
  },
  {
    label: 'Top Rated: High to Low',
    value: SortingOptionsValuesEnum.RATING_SUMMARY_DESC,
  },
  {
    label: 'Reviews Count: High to Low',
    value: SortingOptionsValuesEnum.REVIEWS_COUNT_DESC,
  },
  
];
