import { useApi } from '~/composables/useApi';

export const useFtGenerateFacebookPixelEventId = () => {
  const { query } = useApi();
  
  const generateFacebookPixelEventId:any = async (eventName, entityType, entityID, fbp, clientIpAddress, clientUserAgent) =>{
    const info: any = {
      'event_name': eventName,
      'entity_type': entityType,
      'entity_id': entityID,
      'fbp': fbp,
      'client_ip_address': clientIpAddress,
      'client_user_agent': clientUserAgent,
    };

    const encodeInfo = Buffer.from(JSON.stringify(info)).toString('base64');

    const myGraphqlQuery = `
    query {
      generateFacebookPixelEventId(info: "${encodeInfo}"){
        eventId
      }
    }
    `;

    const { data } = await query(myGraphqlQuery);

    return {
      data,
    };
  }
  
  return {
    generateFacebookPixelEventId,
  }
}


export default useFtGenerateFacebookPixelEventId;