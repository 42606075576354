var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"form",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();handleSubmit(function () { _vm.$emit('submit', _vm.formCopy); })}}},[_c('SfHeading',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-heading'),expression:"'login-modal-heading'"}],staticClass:"sf-heading--left sf-heading--no-underline title desktop-only",attrs:{"level":3,"title":_vm.$t('Create an Account')}}),_vm._v(" "),_c('span',{staticClass:"message desktop-only"},[_vm._v("\n        "+_vm._s(_vm.$t('Create an account to get notified on the latest products, save your favourite items, and view your orders.'))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"form__header smartphone-tablet-only"},[_c('SfHeading',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-heading'),expression:"'login-modal-heading'"}],staticClass:"sf-heading--left sf-heading--no-underline title",attrs:{"level":3,"title":_vm.$t('Create an Account')}})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Email')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-email'),expression:"'login-modal-email'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"email","type":"email"},model:{value:(_vm.formCopy.email),callback:function ($$v) {_vm.$set(_vm.formCopy, "email", $$v)},expression:"formCopy.email"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element-special"},[_c('div',{staticClass:"form__element form__element--half"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('First Name')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-firstName'),expression:"'login-modal-firstName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"first-name"},model:{value:(_vm.formCopy.firstName),callback:function ($$v) {_vm.$set(_vm.formCopy, "firstName", $$v)},expression:"formCopy.firstName"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half form__element--half-even"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Last Name')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-lastName'),expression:"'login-modal-lastName'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"last-name"},model:{value:(_vm.formCopy.lastName),callback:function ($$v) {_vm.$set(_vm.formCopy, "lastName", $$v)},expression:"formCopy.lastName"}})]}}],null,true)})],1)]),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Password')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-password'),expression:"'login-modal-password'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"password","type":"password","has-show-password":"","autocomplete":"new-password"},model:{value:(_vm.formCopy.password),callback:function ($$v) {_vm.$set(_vm.formCopy, "password", $$v)},expression:"formCopy.password"}})]}}],null,true)})],1),_vm._v(" "),_c('div',{staticClass:"form__element form__element--half"},[_c('span',{staticClass:"form__element--label"},[_vm._v(_vm._s(_vm.$t('Confirm Password')))]),_vm._v(" "),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('FtInput',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-passwordConfirmation'),expression:"'login-modal-passwordConfirmation'"}],staticClass:"form__element",attrs:{"valid":!errors[0],"error-message":_vm.$t(errors[0]),"name":"passwordConfirmation","type":"password","has-show-password":""},model:{value:(_vm.formCopy.passwordConfirmation),callback:function ($$v) {_vm.$set(_vm.formCopy, "passwordConfirmation", $$v)},expression:"formCopy.passwordConfirmation"}})]}}],null,true)})],1),_vm._v(" "),(_vm.showRecaptcha)?_c('recaptcha'):_vm._e(),_vm._v(" "),_vm._t("error"),_vm._v(" "),_c('SfButton',{directives:[{name:"e2e",rawName:"v-e2e",value:('login-modal-submit'),expression:"'login-modal-submit'"}],staticClass:"sf-button--full-width form__button",attrs:{"data-testid":"login-form-submit","type":"submit","disabled":_vm.loading || invalid}},[_c('SfLoader',{class:{ loader: _vm.loading },attrs:{"loading":_vm.loading}},[_c('div',[_vm._v(_vm._s(_vm.$t('Create account')))])])],1)],2)]}}],null,true)}),_vm._v(" "),_c('span',{staticClass:"form__back-to-login",on:{"click":function($event){return _vm.changeForm('login')}}},[_vm._v("\n    "+_vm._s(_vm.$t('Back'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"form__declared-msg"},[_c('span',[_vm._v(_vm._s(_vm.$t('By clicking on “Create account” I confirm that I have read and')))]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('agree to Fortytwo’s'))+" \n      "),_c('Nuxt-link',{staticClass:"form__declared-msg--link",attrs:{"to":_vm.localePath('/terms-of-service/')}},[_vm._v(_vm._s(_vm.$t('Terms & Conditions')))]),_vm._v(" "+_vm._s(_vm.$t('and'))+"\n      "),_c('Nuxt-link',{staticClass:"form__declared-msg--link",attrs:{"to":_vm.localePath('/privacy-policy/')}},[_vm._v(_vm._s(_vm.$t('Privacy Policy')))]),_vm._v(".\n    ")],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }