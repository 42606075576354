/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default `
  query getFacetData($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      items {
        __typename
        uid
        sku
        name
        stock_status
        only_x_left_in_stock
        crossed_out_list_price
        product_brand_label
        product_category
        calculated_lead_time
        width
        height
        depth
        length
        diameter
        thumbnail {
          url
          position
          disabled
          label
        }
        secondary_image {
          url
        }
        url_key
        url_rewrites {
          url
        }
        price_range {
          maximum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
          minimum_price {
            final_price {
              currency
              value
            }
            regular_price {
              currency
              value
            }
          }
        }
        mp_label_data {
          name
          rule_id
          customer_group_ids
          label
          label_color
          label_css
          label_font
          label_image
          label_position
          label_template
        }
        ... on GroupedProduct {
          items {
            product {
              sku
            }
          }
        }
        ... on BundleProduct  {
          bundle_main_price {
            price
            final_price
          }
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_uid
            label
            position
            uid
            use_default
            values {
              label
              swatch_data {
                value
              }
              uid
            }
          }
          category_custom_swatch {
            swatch_data {
              uid
              swatch_value
              variation_base_image_position
              product_image
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
