import { useApi } from '~/composables/useApi';
import { useCart } from '~/modules/checkout/composables/useCart';
import { ref } from "@nuxtjs/composition-api";
import { merge } from 'lodash-es';

export interface EmptyShoppingCart {
  error: Boolean
  message: String
}

export const useFtCart = () => {
  const { query } = useApi();
  const { cart } = useCart();

  const saveEmptyShoppingCartResponse = ref();

  const emptyShoppingCart = async () => {
    const graphqlQuery = `
    mutation {
      emptyShoppingCart(
        input: {
          cart_id: "${cart.value.id}",
      }) {
        error
        message
      }
    }
    `;

    const { data } = await query<{ emptyShoppingCart: EmptyShoppingCart }>(graphqlQuery);
    const { emptyShoppingCart } = data;

    saveEmptyShoppingCartResponse.value = merge({}, saveEmptyShoppingCartResponse.value, emptyShoppingCart);
  }
  
  return {
    emptyShoppingCart,
    saveEmptyShoppingCartResponse,
  }
}

export default useFtCart;