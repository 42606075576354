import { UseContextReturn } from '~/types/core';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables';
import { Logger } from '~/helpers/logger';


export const placeOrderCommand = {
  execute: async (context: UseContextReturn, cartId: string, params?: ComposableFunctionArgs<{}>): Promise<PlaceOrderOutput | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.placeOrder(
      { cart_id: cartId },
      params?.customHeaders,
    );
    Logger.debug('placeOrderCommand', data)
    let order = data?.placeOrder?.order ?? null;

    return {order, errors: errors};
  },
};
