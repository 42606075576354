
























































































































































































import { defineComponent, computed, useRouter, useContext, ref, onMounted } from '@nuxtjs/composition-api';
import { SfBadge } from '@storefront-ui/vue';
import { useUiState } from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'FortytwoHeaderIcon',
  components: {
    SfBadge,
    SearchBar: () => import('~/components/FortyTwo/header/SearchBar/SearchBar.vue'),
    SearchResults: () => import('~/components/FortyTwo/header/SearchBar/SearchResults.vue'),
  },
  props: {
    isSticky: {
      type: Boolean,
      default: false,
    },
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { app } = useContext();
    const wishlistStore = useWishlistStore();
    const { cart } = useCart();
    const { user } = useUser();
    const { toggleCartSidebar, toggleWishlistSidebar, toggleLoginModal, toggleMobileMenu } = useUiState();
    const customerStore = useCustomerStore();
    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);
    const userName = computed(() => (user.value?.lastname 
    ? user.value?.lastname?.length <= 12 ? user.value?.lastname?.toUpperCase() : 'PROFILE'
    : 'LOGIN'));

    const handleAccountClick = async () => {
      if (app.$vsf.$magento.config.state.getCustomerToken()) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        await customerStore.setTriggerLoginModal(true);
      }
    };

    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit("set-is-open", true);
      }
    };

    const hideSearch = () => {
      if (props.isSearchOpen) {
        emit("set-is-open", false);
      }
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    return {
      userName,
      handleAccountClick,
      toggleCartSidebar,
      toggleWishlistSidebar,
      cartTotalItems: computed(() => cart.value ? cart.value?.total_quantity < 100 ? cart.value?.total_quantity : '99+' : 0),
      cartGrandTotal: computed(() => cart.value?.prices?.grand_total?.value ?? 0),
      wishlistHasProducts,
      wishlistItemsQty,
      cart,
      toggleMobileMenu,
      toggleSearch,
      showSearch,
      hideSearch,

    }
  }
})
