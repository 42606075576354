

















import { defineComponent } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';

export default defineComponent({
   name: 'FortytwoHeaderTop',
   components: {
        SfIcon,
    },
})
