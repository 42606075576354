export default function scrollBehavior(_to, _from, savedPosition) {
  const behavior = {behavior:'instant'}
  if (savedPosition){
    return {...savedPosition, ...behavior }
  }
  return {
    x: 0,
    y: 0,
    ...behavior
  };
}
