import type { Plugin } from '@nuxt/types';
import type { ApolloQueryResult } from '@apollo/client/core/types';
import type { UiNotification } from '~/composables/useUiNotification';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import {
  useRoute,
} from '@nuxtjs/composition-api';

export const hasGraphqlAuthorizationError = (res: ApolloQueryResult<unknown>) => res?.errors
  ?.some((error) => error?.extensions?.category === 'graphql-authorization') ?? false;

const plugin : Plugin = ({ $pinia, app }) => {
  const { path } = app.context.route;
  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${app.context.i18n.locale}`, '');

  const customerStore = useCustomerStore($pinia);
  if (app.$vsf.$magento.config.state.getCustomerToken()) {
    customerStore.setIsLoggedIn(true);
  }

  if (clearUrl.slice(0, 23) !== '/customer/order-history') {
    app.$vsf.$magento.client.interceptors.response.use((res) => {
      if (!hasGraphqlAuthorizationError(res.data as ApolloQueryResult<unknown>)) {
        return res;
      }
      customerStore.setIsLoggedIn(false);
      app.$vsf.$magento.config.state.removeCustomerToken();
      app.$vsf.$magento.config.state.removeCartId();
      app.$vsf.$magento.config.state.setMessage<UiNotification>({
        id: Symbol('not_authorized'),
        message: app.i18n.t('You are not authorized, please log in.') as string,
        type: 'warning',
        icon: null,
        persist: true,
        title: null,
      });

      app.router.push(app.localePath('/'));

      return res;
    });
  }
};

export default plugin;
