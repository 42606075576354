










































































































import { defineComponent, ref, watch, onMounted } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import {
  SfInput,
  SfButton,
  SfLoader,
  SfHeading,
} from '@storefront-ui/vue';
import FtInput from "~/components/customSFUI/vue/src/components/atoms/FtInput/FtInput.vue";
import { LoginFormFields, FormName } from '~/modules/customer/components/LoginModal/forms/types';
import FtButton from "~/components/customSFUI/vue/src/components/atoms/FtButton/FtButton.vue";

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  name: 'FortytwoLoginForm',
  components: {
    SfInput,
    SfButton,
    SfLoader,
    SfHeading,
    FtInput,
    ValidationObserver,
    ValidationProvider,
    FtButton,
  },
  props: {
    form: {
      type: Object as PropType<LoginFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formCopy = ref<LoginFormFields>();
    watch(() => props.form, (newForm) => { formCopy.value = { ...newForm }; }, { immediate: true, deep: true });
    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };
    const customerStore = useCustomerStore();
    const { triggerResetPasswordModal } = useCustomerStore();
    onMounted( async () => {
      // the trigger variable is set at reset password. it trigger while reset password process fails.
      if(triggerResetPasswordModal){
        await customerStore.setTriggerResetPasswordModal(false);
        await changeForm('forgotPassword');
      }   
    });

    return {
      formCopy,
      changeForm,
    };
  },
});
