






import { defineComponent, useRouter, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeaderNavigationItemCustom',
  props: {
    label: {
      type: String,
      default: '',
    },
    link: {
      type: [String, Object],
      default: '',
    },
    custom_class: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { localePath } = useContext();
    const router = useRouter();
    const navigate = (path) => {
      router.push(localePath('/'+path));
    };

    return {
      navigate,
    };
  },
});
