import { useApi } from '~/composables/useApi';

export const useFTGetCustomerGroupId = () => {
  const { query } = useApi();
  const getCustomerGroupId:any = async () =>{
    const myGraphqlQuery = `
      query {
        getCustomerGroupId{
          cust_grp_id
        }
      }
      `;  
    const { data } = await query(myGraphqlQuery);

    return {
      data,
    };
  }
  
  return {
    getCustomerGroupId,
  }
}


export default useFTGetCustomerGroupId;