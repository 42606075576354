








import { defineComponent, ref, onMounted, onUnmounted, useContext } from "@nuxtjs/composition-api";
import { debounce } from 'lodash-es';

export default defineComponent({
    name: "BackToTop",
    setup() {
        const { app } = useContext();
        const isScrollVisible = ref(false);

        const handleScroll = () =>{
        if (window.scrollY > 500){
            isScrollVisible.value = true;
        }
        else{
            isScrollVisible.value = false;
        }
        }

        const delayedHandleScroll = debounce(() =>{
        if (window.scrollY > 500){
            isScrollVisible.value = true;
        }
        else{
            isScrollVisible.value = false;
        }
        }, 500);

        onMounted(() => {
        if (app.$device.isDesktop) {
        window.addEventListener("scroll", handleScroll);
        }
        });

        onUnmounted(() => {
        if (app.$device.isDesktop) {
        window.removeEventListener("scroll", handleScroll);
        }
        });

        return {
            isScrollVisible
        }
    
    }, 
    methods: {
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        }
    }
})

