import type { MetaInfo } from 'vue-meta';
import { useConfigStore } from '~/stores/config';
import { Logger } from '~/helpers/logger';

export const getMetaInfo = (page: any, isNoIndex: boolean = false): MetaInfo => {
  const { storeConfig } = useConfigStore();

  const seoTags: MetaInfo = {
    meta: [],
  };

  if (page?.meta_title || page?.title || page?.name || storeConfig?.default_title) {
    seoTags.title = page?.meta_title || page?.title || page?.name || storeConfig?.default_title;
  }
  
  seoTags.title = `${(storeConfig.title_prefix ? storeConfig.title_prefix + ' ' : '')}${seoTags.title}${(storeConfig.title_suffix ? ' ' + storeConfig.title_suffix : '')}`
  Logger.debug('seoTags.title', seoTags.title);

  seoTags.meta.push({
    hid: 'og:title',
    name: 'og:title',
    content: seoTags.title,
  });

  seoTags.meta.push({
    hid: 'apple-mobile-web-app-title',
    name: 'apple-mobile-web-app-title',
    content: 'FortyTwo',
  });

  seoTags.meta.push({
    hid: 'og:site_name',
    name: 'og:site_name',
    content: 'FortyTwo',
  });

  // seoTags.title = (storeConfig.title_prefix ? storeConfig.title_prefix + ' ' : '') + seoTags.title + (storeConfig.title_suffix ? ' ' + storeConfig.title_suffix : '');
  if (page?.meta_description || storeConfig.default_description) {
    seoTags.meta.push({
      hid: 'description',
      name: 'description',
      content: page?.meta_description || storeConfig.default_description,
    });

    seoTags.meta.push({
      hid: 'og:description',
      name: 'og:description',
      content: page?.meta_description || storeConfig.default_description,
    });
  }
  if (page?.meta_keyword || page?.meta_keywords || storeConfig.default_keywords) {
    seoTags.meta.push({
      hid: 'keywords',
      name: 'keywords',
      content: page?.meta_keyword || page?.meta_keywords || storeConfig.default_keywords,
    });

    seoTags.meta.push({
      hid: 'og:keywords',
      name: 'og:keywords',
      content: page?.meta_keyword || page?.meta_keywords || storeConfig.default_keywords,
    });
  }
  if (isNoIndex) {
    seoTags.meta.push({
      name: 'robots',
      content: 'noindex, nofollow',
    });
  }

  return seoTags;
};
