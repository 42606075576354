import { useApi } from '~/composables/useApi';

export const useFtGetMenuInfo = () => {
  const { query } = useApi();

  const getMenuInfo:any = async () =>{
    const myGraphqlQuery = `
        query{
          menuInfor{
            item
            {
              id
              name
              identifier
              url_key
              content
              custom_class
              type
            }
          }
        }
      `;  
    const { data } = await query(myGraphqlQuery);
    
    return {
      data,
    };
  }
  
  return {
    getMenuInfo,
  }
}


export default useFtGetMenuInfo;