import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _1d513f9d = () => interopDefault(import('../modules/checkout/pages/Cart.vue' /* webpackChunkName: "" */))
const _35ad7f83 = () => interopDefault(import('../modules/fortytwo/pages/Payment/CyberSource/Cancel.vue' /* webpackChunkName: "" */))
const _645bafa5 = () => interopDefault(import('../modules/fortytwo/pages/Payment/CyberSource/Redirect.vue' /* webpackChunkName: "" */))
const _fd11dcec = () => interopDefault(import('../modules/fortytwo/pages/Payment/CyberSource/Response.vue' /* webpackChunkName: "" */))
const _494cb10e = () => interopDefault(import('../modules/checkout/pages/Checkout/V1/Checkout.vue' /* webpackChunkName: "" */))
const _0d3f1563 = () => interopDefault(import('../modules/checkout/pages/Checkout/V1/Billing.vue' /* webpackChunkName: "" */))
const _3b79938a = () => interopDefault(import('../modules/checkout/pages/Checkout/V1/DeliveryDate.vue' /* webpackChunkName: "" */))
const _22ffe5ae = () => interopDefault(import('../modules/checkout/pages/Checkout/V1/OrderReview.vue' /* webpackChunkName: "" */))
const _a8233124 = () => interopDefault(import('../modules/checkout/pages/Checkout/V1/Payment.vue' /* webpackChunkName: "" */))
const _4b446514 = () => interopDefault(import('../modules/checkout/pages/Checkout/V1/Shipping.vue' /* webpackChunkName: "" */))
const _a5ccad1e = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _740316af = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _2e51f63e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _4b19c3ac = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _236eec38 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _6e20a5b0 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _4ccbb92c = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _4be06504 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _6c8eb428 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _0fa47167 = () => interopDefault(import('../modules/customer/pages/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _ea4d8d10 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _3f59f33c = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _35382187 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _03c11c08 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _763b5a69 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _b22c125c = () => interopDefault(import('../pages/mattress-finder.vue' /* webpackChunkName: "pages/mattress-finder" */))
const _030617fc = () => interopDefault(import('../pages/mattresses/index.vue' /* webpackChunkName: "pages/mattresses/index" */))
const _731f1fce = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _7cb467cd = () => interopDefault(import('../modules/fortytwo/pages/Payment/Paypal/PaypalRedirect.vue' /* webpackChunkName: "" */))
const _6964b6de = () => interopDefault(import('../modules/fortytwo/pages/Payment/Paypos/PayPOS.vue' /* webpackChunkName: "" */))
const _1aad998d = () => interopDefault(import('../modules/fortytwo/pages/Payment/Paypos/PayPOSRedirect.vue' /* webpackChunkName: "" */))
const _1028b5dd = () => interopDefault(import('../modules/fortytwo/pages/Payment/RedirectMagento.vue' /* webpackChunkName: "" */))
const _980f2b9c = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "" */))
const _c839c676 = () => interopDefault(import('../pages/SearchWithEmpty.vue' /* webpackChunkName: "pages/SearchWithEmpty" */))
const _a02c7b62 = () => interopDefault(import('../modules/fortytwo/pages/Quotation/SetAvailable.vue' /* webpackChunkName: "" */))
const _89f83c5e = () => interopDefault(import('../pages/top-10-mattresses.vue' /* webpackChunkName: "pages/top-10-mattresses" */))
const _a3af6ae2 = () => interopDefault(import('../pages/trending.vue' /* webpackChunkName: "pages/trending" */))
const _2b2a7aad = () => interopDefault(import('../modules/checkout/pages/Checkout/V2/Checkout.vue' /* webpackChunkName: "" */))
const _087bc838 = () => interopDefault(import('../modules/checkout/pages/Checkout/V2/Billing.vue' /* webpackChunkName: "" */))
const _000b75a9 = () => interopDefault(import('../modules/checkout/pages/Checkout/V2/DeliveryDate.vue' /* webpackChunkName: "" */))
const _cb1d2422 = () => interopDefault(import('../modules/checkout/pages/Checkout/V2/Payment.vue' /* webpackChunkName: "" */))
const _8788d1d6 = () => interopDefault(import('../modules/checkout/pages/Checkout/V2/Shipping.vue' /* webpackChunkName: "" */))
const _63d99116 = () => interopDefault(import('../pages/furniture/chairs.vue' /* webpackChunkName: "pages/furniture/chairs" */))
const _c28cd890 = () => interopDefault(import('../pages/mattresses/shop-by-brand.vue' /* webpackChunkName: "pages/mattresses/shop-by-brand" */))
const _75500cdc = () => interopDefault(import('../pages/mattresses/test.vue' /* webpackChunkName: "pages/mattresses/test" */))
const _531b24d4 = () => interopDefault(import('../modules/fortytwo/pages/Payment/CyberSource/PaymentLink/Redirect.vue' /* webpackChunkName: "" */))
const _714bb658 = () => interopDefault(import('../modules/fortytwo/pages/Payment/Paypal/CancelRedirect.vue' /* webpackChunkName: "" */))
const _313315ba = () => interopDefault(import('../modules/fortytwo/pages/Payment/Paypal/Redirect.vue' /* webpackChunkName: "" */))
const _41b87e55 = () => interopDefault(import('../pages/furniture/living-room/sofas.vue' /* webpackChunkName: "pages/furniture/living-room/sofas" */))
const _7f32cb42 = () => interopDefault(import('../pages/reviewsadmin/product/orderItems/oid/_oid/index.vue' /* webpackChunkName: "pages/reviewsadmin/product/orderItems/oid/_oid/index" */))
const _1359678a = () => interopDefault(import('../modules/review/pages/ProductReviewList.vue' /* webpackChunkName: "" */))
const _30328373 = () => interopDefault(import('../modules/review/pages/Order.vue' /* webpackChunkName: "" */))
const _d3446e5c = () => interopDefault(import('../modules/review/pages/OldOrder.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/cart",
    component: _1d513f9d,
    name: "cart___sg"
  }, {
    path: "/chcybersource-cancel",
    component: _35ad7f83,
    name: "chcybersource-cancel___sg"
  }, {
    path: "/chcybersource-redirect",
    component: _645bafa5,
    name: "chcybersource-redirect___sg"
  }, {
    path: "/chcybersource-response",
    component: _fd11dcec,
    name: "chcybersource-response___sg"
  }, {
    path: "/checkout",
    component: _494cb10e,
    name: "v1-checkout___sg",
    children: [{
      path: "billing",
      component: _0d3f1563,
      name: "v1-billing___sg"
    }, {
      path: "delivery-date",
      component: _3b79938a,
      name: "v1-delivery-date___sg"
    }, {
      path: "order-review",
      component: _22ffe5ae,
      name: "v1-order-review___sg"
    }, {
      path: "payment",
      component: _a8233124,
      name: "v1-payment___sg"
    }, {
      path: "shipping",
      component: _4b446514,
      name: "v1-shipping___sg"
    }, {
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you___sg"
    }]
  }, {
    path: "/Cms",
    component: _740316af,
    name: "Cms___sg"
  }, {
    path: "/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer___sg",
    children: [{
      path: "addresses-details",
      component: _4b19c3ac,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___sg"
    }, {
      path: "my-newsletter",
      component: _236eec38,
      meta: {"titleLabel":"My newsletter"},
      name: "customer-my-newsletter___sg"
    }, {
      path: "my-profile",
      component: _6e20a5b0,
      meta: {"titleLabel":"My profile"},
      name: "customer-my-profile___sg"
    }, {
      path: "my-reviews",
      component: _4ccbb92c,
      meta: {"titleLabel":"My reviews"},
      name: "customer-my-reviews___sg"
    }, {
      path: "my-wishlist",
      component: _4be06504,
      meta: {"titleLabel":"My wishlist"},
      name: "customer-my-wishlist___sg"
    }, {
      path: "order-history",
      component: _6c8eb428,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___sg"
    }, {
      path: "/reset-password",
      component: _0fa47167,
      alias: "/customer/account/createPassword",
      name: "reset-password___sg"
    }, {
      path: "addresses-details/create",
      component: _ea4d8d10,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___sg"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _3f59f33c,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___sg"
    }, {
      path: "order-history/:orderId",
      component: _35382187,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___sg"
    }]
  }, {
    path: "/Faq",
    component: _03c11c08,
    name: "Faq___sg"
  }, {
    path: "/Home",
    component: _763b5a69,
    name: "Home___sg"
  }, {
    path: "/mattress-finder",
    component: _b22c125c,
    name: "mattress-finder___sg"
  }, {
    path: "/mattresses",
    component: _030617fc,
    name: "mattresses___sg"
  }, {
    path: "/Page",
    component: _731f1fce,
    name: "Page___sg"
  }, {
    path: "/paypal_express-redirect",
    component: _7cb467cd,
    name: "paypal_express-redirect___sg"
  }, {
    path: "/paypos",
    component: _6964b6de,
    name: "paypos___sg"
  }, {
    path: "/paypos-redirect",
    component: _1aad998d,
    name: "paypos-redirect___sg"
  }, {
    path: "/redirect-to-magento",
    component: _1028b5dd,
    name: "redirect-to-magento___sg"
  }, {
    path: "/search",
    component: _980f2b9c,
    name: "search___sg"
  }, {
    path: "/Search",
    component: _980f2b9c,
    name: "Search___sg"
  }, {
    path: "/SearchWithEmpty",
    component: _c839c676,
    name: "SearchWithEmpty___sg"
  }, {
    path: "/set-quotation-available",
    component: _a02c7b62,
    name: "set-quotation-available___sg"
  }, {
    path: "/top-10-mattresses",
    component: _89f83c5e,
    name: "top-10-mattresses___sg"
  }, {
    path: "/trending",
    component: _a3af6ae2,
    name: "trending___sg"
  }, {
    path: "/checkout/v2",
    component: _2b2a7aad,
    name: "v2-checkout___sg",
    children: [{
      path: "billing",
      component: _087bc838,
      name: "v2-billing___sg"
    }, {
      path: "delivery-date",
      component: _000b75a9,
      name: "v2-delivery-date___sg"
    }, {
      path: "payment",
      component: _cb1d2422,
      name: "v2-payment___sg"
    }, {
      path: "shipping",
      component: _8788d1d6,
      name: "v2-shipping___sg"
    }]
  }, {
    path: "/furniture/chairs",
    component: _63d99116,
    name: "furniture-chairs___sg"
  }, {
    path: "/mattresses/shop-by-brand",
    component: _c28cd890,
    name: "mattresses-shop-by-brand___sg"
  }, {
    path: "/mattresses/test",
    component: _75500cdc,
    name: "mattresses-test___sg"
  }, {
    path: "/paymentlink/chcybersource-redirect",
    component: _531b24d4,
    name: "chcybersource-mp-redirect___sg"
  }, {
    path: "/paypal/cancelredirect",
    component: _714bb658,
    name: "paypal_express-cancel-redirect___sg"
  }, {
    path: "/paypal/redirect",
    component: _313315ba,
    name: "paypal_express-callback-redirect___sg"
  }, {
    path: "/furniture/living-room/sofas",
    component: _41b87e55,
    name: "furniture-living-room-sofas___sg"
  }, {
    path: "/reviewsadmin/product/orderItems/oid/:oid",
    component: _7f32cb42,
    name: "reviewsadmin-product-orderItems-oid-oid___sg"
  }, {
    path: "/review/product/list/:id",
    component: _1359678a,
    props: true,
    name: "review-product___sg"
  }, {
    path: "/review/order/:oid",
    component: _30328373,
    props: true,
    name: "review-order___sg"
  }, {
    path: "/review-order/:oid",
    component: _d3446e5c,
    props: true,
    name: "review-old-order___sg"
  }, {
    path: "/",
    component: _763b5a69,
    meta: {"keepAlive":true},
    name: "home___sg"
  }, {
    path: "/:slug+",
    component: _731f1fce,
    name: "page___sg"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
