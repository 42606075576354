import { useApi } from '~/composables/useApi';
import axios from "axios";
import { useContext } from '@nuxtjs/composition-api';

export const useFtDynamicYieldEvent = () => {
    const { query } = useApi();
    const { $cookies } = useContext();
    const sendDYEvent = async (eventType:any, paramData:any, paramData1:any) =>{
        try{
            const clientdata = await axios.get('/client-header');
            const clientIpAddress = clientdata.data.clientipaddress;
            const encodedClientIpAddress = window.btoa(clientIpAddress) ?? '';
            const dyid_servers = window.btoa($cookies.get('dyid_server')) ?? '';
            const dyid_sessions = window.btoa($cookies.get('dyid_session')) ?? '';
            const myGraphqlQuery = `
            query {
                dyeventinit(
                event_type: "${eventType}",
                dyid_ipdata: "${encodedClientIpAddress}", 
                dyid_server: "${dyid_servers}",
                dyid_session: "${dyid_sessions}", 
                data:"${paramData}", 
                data1:"${paramData1}") 
                    {
                        event_type
                    }
                }
            `;
            if(clientIpAddress && dyid_servers){ query(myGraphqlQuery); }   
        }catch(error){
            console.error(error);
        }
    }
    return sendDYEvent;
}

export default useFtDynamicYieldEvent;