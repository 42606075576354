







import { useRoute, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'empty',
  setup() {
    const route = useRoute();
    return {
      route,
    };
  },
  head: {
    link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
  },
});
