













import { useRoute, defineComponent, computed } from '@nuxtjs/composition-api';
import AppCheckoutHeader from "~/components/FortyTwo/checkout/V2/AppCheckoutHeader.vue";
import LazyHydrate from "vue-lazy-hydration";
import IconSprite from "~/components/General/IconSprite.vue";
import LoginModal from '~/modules/customer/components/LoginModal/LoginModal.vue';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'checkoutV2',
  components: {
    AppCheckoutHeader,
    LazyHydrate,
    IconSprite,
    LoginModal,
    Notification: () =>
      import(/* webpackPrefetch: true */ "~/components/Notification.vue"),
  },
  setup() {
    const route = useRoute();
    const customerStore = useCustomerStore();

    const toggleLoginModalClose = async () => {
      await customerStore.setTriggerLoginModal(false);
    };

    return {
      route,
      isLoginModalOpen: computed(() => customerStore.triggerLoginModal),
      toggleLoginModalClose,
    };
  },
  head: {
    link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
  },
});
