


import { defineComponent, useContext } from '@nuxtjs/composition-api';
import Vue from "vue";
import VueGtag from "vue-gtag";

export default defineComponent({
   name: 'FortytwoHeaderTopGA4',
    setup() {
        const { $config } = useContext();
        const gtagId = $config.gtagId;
        // ga4 plugin, this will auto tracking the visted pages and also allows others components to use $gtag function. 
        // UAT-VSF config: { id: "G-CX8ZL1EG9J" },
        // fortytwo-ngrok config: { id: "G-CB4G1PDXD6" },
        Vue.use(VueGtag, {
        config: { id: gtagId },
        });
    },
})
