import { Middleware } from '@nuxt/types';
const middleware : Middleware = (context) => {
  const { path } = context.route;
  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');
  if (!context.app.$vsf.$magento.config.state.getCustomerToken() && clearUrl !== "/reset-password" && clearUrl.substring(0,23) !== "/customer/order-history") {
    const homepage = context.localeRoute({ name: 'home' });
    context.redirect(homepage);
  }
};
export default middleware;
