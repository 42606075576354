import { Middleware } from "@nuxt/types";
import { usePageStore } from "~/stores/page";
import { Logger } from "~/helpers/logger";
import { RoutableInterface } from "~/modules/GraphQL/types";

const urlFilterCheck: Middleware = async (context) => {
  const pageStore = usePageStore();
  const { path } = context.route;
  let newUrl = "";

  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, "").replace(`/${context.i18n.locale}`, "");

  if (clearUrl.includes("filter")) {
    const path_array = clearUrl.split("/");

    for (let url_path of path_array) {
      if (url_path !== "filter") {
        if (url_path == "") continue;
        else {
          newUrl += "/" + url_path;
        }
      } else {
        break;
      }
    }

    newUrl += ".html";
    context.redirect(301, newUrl);
  }
};

export default urlFilterCheck;
