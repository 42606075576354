import { useApi } from '~/composables/useApi';

export const useFtGetMobileMenuInfo = () => {
  const { query } = useApi();

  const getMobileMenuInfo:any = async () =>{
    const myGraphqlQuery = `
    query {
      mobileMenuInfor {
        item {
          include_in_menu
          is_anchor
          level
          name
          position
          product_count
          uid
          url_path
          url_suffix
          custom_class
          children {
            include_in_menu
            is_anchor
            level
            name
            position
            product_count
            uid
            url_path
            url_suffix
            custom_class
            children {
              include_in_menu
              is_anchor
              level
              name
              position
              product_count
              uid
              url_path
              url_suffix
              custom_class
              children {
                name
              }
            }
          }
        }
        mobileOnly {
          name
          uid
          url_path
          target
        }
        country {
          name
          uid
          url_path
        }
      }
    }    
      `;  
    const { data } = await query(myGraphqlQuery);
    
    return {
      data,
    };
  }
  
  return {
    getMobileMenuInfo,
  }
}


export default useFtGetMobileMenuInfo;